import React, { useEffect, useState } from 'react'

import withQueryString from '../utils/with-query-string'
import { useLiff } from '../utils/liff'
import {
  getEducationalContentLogCollection,
  withTimestampField,
} from '../utils/firestore'
import { Language } from '../constants/i18n'

interface EducationalContentProps {
  queryString: {
    'liff.state': string
  }
}

const EDUCATIONAL_LINKS = {
  'covid-info': {
    [Language.English]: 'https://www.who.int/health-topics/coronavirus',
    [Language.Thai]:
      'https://www.who.int/thailand/emergencies/novel-coronavirus-2019/advice-for-public',
  },
  handwashing: {
    [Language.English]: 'https://www.who.int/gpsc/clean_hands_protection/en',
    [Language.Thai]:
      'https://www.rama.mahidol.ac.th/ramachannel/home/article/%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%A5%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%96%E0%B8%B9%E0%B8%81%E0%B8%A7%E0%B8%B4%E0%B8%98%E0%B8%B5-%E0%B8%9B%E0%B9%89/',
  },
}

const EducationalContent = ({
  queryString,
}: EducationalContentProps): JSX.Element => {
  const [error, setError] = useState(null)

  const { loading, profile, error: liffError, userLanguage } = useLiff({
    loadLanguage: true,
  })

  useEffect(() => {
    ;(async () => {
      const contentId = Object.keys(queryString)[0]

      if (loading || liffError) {
        return
      }

      if (!contentId || !EDUCATIONAL_LINKS[contentId]) {
        setError('Invalid content, please open from the Line chat bot')
        return
      }

      try {
        const { userId } = profile
        const resourceUrl = EDUCATIONAL_LINKS[contentId][userLanguage]

        await getEducationalContentLogCollection().add(
          withTimestampField(
            {
              userId,
              contentId,
              language: userLanguage,
              content: resourceUrl,
            },
            'created',
          ),
        )

        window.location.replace(resourceUrl)
      } catch (error) {
        setError(error.message || error)
      }
    })()
  }, [loading, profile, liffError, userLanguage, queryString])

  return (
    <div className="p-4">
      {error || liffError ? (
        <span className="text-red-600">Error: {error || liffError}</span>
      ) : (
        'loading...'
      )}
    </div>
  )
}

export default withQueryString(EducationalContent)
